import {http} from '@/http'
import { Util } from "wize-admin"

export default {
  list(data, params) {
    return http.post('/payment-made-batches/list', data, {params})
  },
  get(id) {
    return http.get('/payment-made-batches/' + id)
  },
  uploadBatch(data){
    const formData = Util.getFormData(data);
    return http.post('/payment-made-batches', formData, {headers: { "Content-Type": "multipart/form-data" },})
  },
  downloadBulk(data, params) {
    return http.post('/payment-made-batches/download/bulk', data, {params})
  },
}
