<template>
  <div class="page-container payments-list">
    <div class="page-title-container">
      <h1 class="page-title">Pagos y comisiones</h1>
    </div>

    <div class="table-container">
      <div class="table-header">
        <div
          class="d-flex flex-column flex-md-row justify-content-between align-items-start position-relative"
        >
          <div class="w-full w-md-auto md-flex-1">
            <div
              class="d-flex justify-content-between align-items-center justify-content-lg-start"
            >
              <button
                type="button"
                class="filter d-flex align-items-center justify-content-between border-0"
                @click="showFilters = !showFilters"
              >
                <i class="ri-filter-3-line text-dark font-weight-bold"></i>
                <div>Filtrar pagos</div>
              </button>

              <div class="d-flex d-md-none">
                <button
                  type="button"
                  class="button-tooltip text-dark font-weight-bold"
                  v-b-tooltip.hover
                  title="Recargar"
                  @click="loadData()"
                >
                  <i class="ri-loop-right-line"></i>
                </button>

                <div class="ml-2 mx-md-2 ml-0" v-if="$can('manage', 'pagos_lista_descarga_excel') ||
                  $can('manage', 'pagos_lista_descarga_macro_cuarta') ||
                  $can('manage', 'pagos_lista_descarga_macro_proveedores')
                ">
                  <DropdownTable>
                    <template v-slot:dropdown-icon>
                      <span class="font-weight-bold text-dark">
                        <i class="ri-download-2-line"></i>
                      </span>
                    </template>

                    <template v-slot:dropdown-content>
                      <DropdownItem
                        text="Descargar excel"
                        v-if="$can('manage', 'pagos_lista_descarga_excel')"
                        @click="downloadReport"
                      />
                      <DropdownItem
                        text="Descargar Macro de abonos proveedores"
                        v-if="$can('manage', 'pagos_lista_descarga_macro_proveedores')"
                        @click="downloadMacroBank"
                      />
                      <DropdownItem
                        text="Descargar Macro de abonos 4ta"
                        v-if="$can('manage', 'pagos_lista_descarga_macro_cuarta')"
                        @click="downloadMacroBankRxh"
                      />
                    </template>
                  </DropdownTable>
                </div>
              </div>
            </div>
          </div>

          <section
            class="w-full w-md-auto md-flex-1 d-flex justify-content-end align-items-center mt-2 mt-md-0"
          >
            <div class="d-none d-md-flex">
              <button
                type="button"
                class="button-tooltip text-dark font-weight-bold m-0"
                v-b-tooltip.hover
                title="Recargar"
                @click="loadData()"
              >
                <i class="ri-loop-right-line"></i>
              </button>

              <div class="ml-2 mx-md-2 ml-0"
                v-if="$can('manage', 'pagos_lista_descarga_excel') ||
                  $can('manage', 'pagos_lista_descarga_macro_cuarta') ||
                  $can('manage', 'pagos_lista_descarga_macro_proveedores')
                ">
                <DropdownTable>
                  <template v-slot:dropdown-icon>
                    <span class="font-weight-bold text-dark">
                      <i class="ri-download-2-line"></i>
                    </span>
                  </template>

                  <template v-slot:dropdown-content>
                    <DropdownItem
                      text="Descargar excel"
                      v-if="$can('manage', 'pagos_lista_descarga_excel')"
                      @click="downloadReport"
                    />
                    <DropdownItem
                      text="Descargar Macro de abonos proveedores"
                      v-if="$can('manage', 'pagos_lista_descarga_macro_proveedores')"
                      @click="downloadMacroBank"
                    />
                    <DropdownItem
                      text="Descargar Macro de abonos 4ta"
                      v-if="$can('manage', 'pagos_lista_descarga_macro_cuarta')"
                      @click="downloadMacroBankRxh"
                    />
                    <DropdownItem
                      text="Descargar excel reporte de gastos"
                      v-if="$can('manage', 'pagos_lista_descarga_excel_contabilidad')"
                      @click="downloadAccounting"
                    />
                  </template>
                </DropdownTable>
              </div>
            </div>

            <div class="w-full w-md-auto d-flex flex-column flex-md-row">
              <SearchBar
                placeholder="Buscar por  RUC, razón social, número de factura"
                @search="onSearch"
                @input="search = $event"
                class="flex-1"
              />
            </div>
          </section>

          <filter-menu
            :show="showFilters"
            @hide="showFilters = false"
            :options="filterOptions"
            :selectedOptionFilter="filters"
            @setFilters="setFilters"
          ></filter-menu>
        </div>

        <div
          class="w-100 d-flex flex-wrap align-items-center mt-2"
          v-if="showFiltersList"
        >
          <span
            v-for="(val, name) in filterList"
            v-bind:key="name"
            class="filter-item"
          >
            {{ getFilterName(name, val) }}
            <button
              type="button"
              class="text-white p-0 bg-transparent border-0 ml-2"
              @click="deleteFilter(name)"
            >
              <i class="ri-close-circle-line"></i>
            </button>
          </span>
        </div>
      </div>

      <div class="mb-0">
        <!-- Mobile -->
        <div class="custom-table-mobile d-xl-none">
          <section class="custom-table-mobile-header">
            <div>
              <span>Id de pago</span>
            </div>
            <div>
              <span>Fecha de registro</span>
            </div>
          </section>

          <div class="custom-table-mobile-body">
            <div class="custom-table-mobile-row" v-for="o in list" :key="o.id">
              <div class="custom-table-mobile-grid">
                <div>
                  <span>{{ o.code }}</span>
                </div>

                <div>
                  <span v-local-date="o.registerDate"></span>
                </div>

                <div>
                  <button
                    type="button"
                    class="border-0 bg-transparent"
                    @click="o.showMobileData = !o.showMobileData"
                  >
                    <span v-if="!o.showMobileData">
                      <i class="ri-arrow-down-s-line"></i>
                    </span>
                    <span v-else>
                      <i class="ri-arrow-up-s-line"></i>
                    </span>
                  </button>
                </div>
              </div>

              <div
                class="custom-table-mobile-options mt-3"
                v-if="
                  o.status === PaymentStatus.PENDING.key ||
                  o.status === PaymentStatus.APPROVED.key ||
                  o.status === PaymentStatus.REVIEW.key ||
                  o.status === PaymentStatus.REJECTED.key ||
                  o.voucher
                "
              >
                <router-link
                  :to="{
                    name: 'payments-upload-voucher',
                    params: { id: o.id },
                  }"
                  v-if="$can('manage', 'pagos_lista_carga_comprobante') &&
                    o.status === PaymentStatus.PENDING.key"
                  class="mr-2"
                >
                  Subir comprobante
                </router-link>

                <a
                  href=""
                  @click.prevent="download(o.voucher.asset)"
                  v-if="$can('manage', 'pagos_lista_descarga_comprobante') && o.voucher"
                  class="mr-2"
                  >Descargar comprobante</a
                >

                <a
                  href=""
                  @click.prevent="download(o.paymentFile)"
                  v-if="$can('manage', 'pagos_lista_descarga_constancia') && o.paymentFile"
                  class="mr-2"
                >Descargar constancia de pago</a
                >

                <a
                  href=""
                  @click.prevent="openPayedModal(o.id)"
                  v-if="$can('manage', 'pagos_lista_pagar')
                    && o.status === PaymentStatus.APPROVED.key"
                  class="mr-2"
                  >Marcar como pagado</a
                >

                <a
                  href=""
                  @click.prevent="openAuthorizeModal(o)"
                  v-if="$can('manage', 'pagos_lista_autorizar') &&
                    o.status === PaymentStatus.REVIEW.key"
                  class="mr-2"
                  >Autorizar</a
                >

                <a
                  href=""
                  @click.prevent="openReasonModal(o.id)"
                  v-if="$can('manage', 'pagos_lista_rechazar') && o.status === PaymentStatus.REVIEW.key"
                  class="mr-2"
                  >Rechazar</a
                >

                <a
                  href=""
                  @click.prevent="seeRejectReason(o.rejectReason)"
                  v-if="$can('manage', 'pagos_lista_ver_rechazo') &&
                    o.status === PaymentStatus.REJECTED.key"
                  class="mr-2"
                  >Ver motivo de rechazo</a
                >
              </div>

              <div class="pt-3" v-if="o.showMobileData">
                <ul class="list-unstyled p-0">
                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                    >Empresa:</span
                    >
                    <span>{{ o.business ? o.business.name : '' }}</span>
                  </li>
                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >RUC:</span
                    >
                    <span>{{ o.companyId }}</span>
                  </li>

                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Razón social:</span
                    >
                    <span>{{ o.companyName }}</span>
                  </li>
                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                    >Verificado:</span
                    >
                    <span>{{ o.bankAccountVerified ? 'SÍ' : 'NO' }}</span>
                  </li>
                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                    >Identificador:</span
                    >
                    <span>{{ o.identifier }}</span>
                  </li>
                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                    >Periodo:</span
                    >
                    <span>{{ o.period }}</span>
                  </li>
                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Monto facturado:</span
                    >
                    <span v-decimal:pen="o.amount"></span>
                  </li>
                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                    >Fecha de aprobación:</span
                    >
                    <span v-local-date="o.approvedAt"></span>
                  </li>
                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Monto abonado:</span
                    >
                    <span v-decimal:pen="o.payedAmount"></span>
                  </li>

                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Fecha de pago:</span
                    >
                    <span v-local-date="o.paymentDate"></span>
                  </li>

                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Estado:</span
                    >
                    <span :class="`badge ${getStatus(o.status).color}`">{{
                      o.statusDesc ? o.statusDesc : getStatus(o.status).label
                    }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <!-- Desktop -->
        <table class="custom-table w-100 d-none d-xl-table">
          <thead>
            <tr>
              <th>Id de pago</th>
              <th>Fecha de <br> registro</th>
              <th>Empresa</th>
              <th>RUC</th>
              <th>Razón <br> social</th>
              <th>Verificado</th>
              <th>Identificador</th>
              <th>Periodo</th>
              <th>Monto <br> facturado</th>
              <th>
                Fecha <br /> de aprobación
              </th>
              <th>Monto <br> abonado</th>
              <th>
                Fecha <br /> de pago
              </th>
              <th>Estado</th>
              <th class="pr-md-3" v-if="$can('manage', 'pagos_lista_carga_comprobante') ||
                  $can('manage', 'pagos_lista_descarga_comprobante') ||
                  $can('manage', 'pagos_lista_pagar') ||
                  $can('manage', 'pagos_lista_autorizar') ||
                  $can('manage', 'pagos_lista_rechazar') ||
                  $can('manage', 'pagos_lista_ver_rechazo')">Opciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="o in list" :key="o.id">
              <td class="cell-center px-1"><p class="payment-id">{{ o.code }}</p></td>
              <td class="cell-center" v-local-date="o.registerDate"></td>
              <td class="cell-center">{{ o.business ? o.business.name : '' }}</td>
              <td class="cell-center">{{ o.companyId }}</td>
              <td>
                <p class="company-name">
                  {{ o.companyName }}<br v-if="o.pusher">  <span v-if="o.pusher" class="pusher-name">{{ o.pusher ? 'Imp: ' + o.pusher.firstName + ' ' + o.pusher.lastName : ''}}</span>
                </p>
              </td>
              <td class="cell-center px-2">{{ o.bankAccountVerified ? 'SÍ' : 'NO' }}</td>
              <td class="cell-center">{{ o.identifier }}</td>
              <td class="cell-center">{{ o.period }}</td>
              <td class="cell-center" v-decimal:pen="o.amount"></td>
              <td class="cell-center" v-local-date="o.approvedAt"></td>
              <td class="cell-center" v-decimal:pen="o.payedAmount"></td>
              <td class="cell-center" v-local-date="o.paymentDate"></td>
              <td class="cell-center">
                <span :class="`badge ${getStatus(o.status).color}`">{{
                    o.statusDesc ? o.statusDesc : getStatus(o.status).label
                }}</span>
              </td>

              <td class="cell-center d_option pr-md-2 position-relative"
                v-if="$can('manage', 'pagos_lista_carga_comprobante') ||
                  $can('manage', 'pagos_lista_descarga_comprobante') ||
                  $can('manage', 'pagos_lista_pagar') ||
                  $can('manage', 'pagos_lista_autorizar') ||
                  $can('manage', 'pagos_lista_rechazar') ||
                  $can('manage', 'pagos_lista_ver_rechazo')">
                <DropdownTable
                  v-if="
                    o.status === PaymentStatus.PENDING.key ||
                    o.status === PaymentStatus.APPROVED.key ||
                    o.status === PaymentStatus.REVIEW.key ||
                    o.status === PaymentStatus.REJECTED.key ||
                    o.voucher
                  "
                >
                  <template v-slot:dropdown-content>
                    <DropdownItem
                      text="Subir comprobante"
                      :to="{
                        name: 'payments-upload-voucher',
                        params: { id: o.id },
                      }"
                      v-if="$can('manage', 'pagos_lista_carga_comprobante')
                        && canUploadVoucher(o)"
                    />
                    <DropdownItem
                      text="Descargar comprobante"
                      @click="download(o.voucher.asset)"
                      v-if="$can('manage', 'pagos_lista_descarga_comprobante')
                        &&  o.voucher"
                    />
                    <DropdownItem
                      text="Descargar constancia de pago"
                      @click="download(o.paymentFile)"
                      v-if="$can('manage', 'pagos_lista_descarga_constancia')
                        &&  o.paymentFile"
                    />
                    <DropdownItem
                      text="Marcar como pagado"
                      @click="openPayedModal(o.id)"
                      v-if="$can('manage', 'pagos_lista_pagar')
                        && o.status === PaymentStatus.APPROVED.key"
                    />
                    <DropdownItem
                      text="Autorizar"
                      @click="openAuthorizeModal(o)"
                      v-if="$can('manage', 'pagos_lista_autorizar')
                        && o.status === PaymentStatus.REVIEW.key"
                    />
                    <DropdownItem
                      text="Rechazar"
                      @click="openReasonModal(o.id)"
                      v-if="$can('manage', 'pagos_lista_rechazar')
                        && o.status === PaymentStatus.REVIEW.key"
                    />
                    <DropdownItem
                      text="Ver motivo de rechazo"
                      @click="seeRejectReason(o.rejectReason)"
                      v-if="$can('manage', 'pagos_lista_ver_rechazo')
                        && o.status === PaymentStatus.REJECTED.key"
                    />
                    <DropdownItem
                      text="Anular"
                      @click="cancelPayment(o.id)"
                      v-if="$can('manage', 'pagos_lista_anular')
                        && (o.status === PaymentStatus.PENDING.key || o.status === PaymentStatus.REVIEW.key ||
                          o.status === PaymentStatus.AUTHORIZED.key ||  o.status === PaymentStatus.REJECTED.key ||
                          o.status === PaymentStatus.APPROVED.key)"
                    />
                  </template>
                </DropdownTable>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="table-footer d-flex justify-content-end align-items-center">
        <pagination
          :total="total"
          :page="page"
          :page-size="pageSize"
          :pages="pages"
          :changePage="changePage"
          :changePageSize="changePageSize"
          v-if="list.length"
        />

        <div class="table-no-results" v-else>
          <p class="m-0 px-3">No se encontraron resultados.</p>
        </div>
      </div>
    </div>
    <RejectReasonModal
      :show="showRejectReasonModal"
      @hide="showRejectReasonModal = false"
      :reject-reason="selectedRejectReason"
    />
    <ReasonForRejectionModal
      :payment-id="selectedPaymentId"
      :reject-reasons="rejectReasons"
      :show="showReasonsModal"
      @hide="showReasonsModal = false"
      @confirm="reject"
    />
    <AuthorizeModal
      :payment-id="selectedPaymentId"
      :show="showAuthorizeModal"
      :project-types="projectTypes"
      @hide="showAuthorizeModal = false"
      @confirm="changeStatus"
    />
    <PayedModal
      :payment-id="selectedPaymentId"
      :show="showPayedModal"
      @hide="showPayedModal = false"
      @confirm="changeStatus"
    />
  </div>
</template>

<script>
import { Constants as LocalConstants } from "@/core/utils";
import {
  AuthorizeModal,
  PayedModal,
  ReasonForRejectionModal,
  RejectReasonModal,
} from "@/core/components";
import {
  BusinessService,
  PaymentBatchService,
  PaymentService,
  ProjectTypeService,
  RejectReasonService,
  ZonalService,
} from "@/core/services";
import fileDownload from "js-file-download";

import {
  Alert,
  Constants,
  DropdownItem,
  DropdownTable,
  FilterMenu,
  FilterMixin,
  PaginationMixin,
  SearchBar,
  Util,
} from "wize-admin";

export default {
  components: {
    ReasonForRejectionModal,
    AuthorizeModal,
    SearchBar,
    DropdownTable,
    DropdownItem,
    FilterMenu,
    RejectReasonModal,
    PayedModal
  },
  data() {
    return {
      selectedPaymentId: null,
      list: [],
      projectTypes: [],
      rejectReasons: [],
      identifiers: [],
      PaymentStatus: LocalConstants.PaymentStatus,
      showRejectReasonModal: false,
      showAuthorizeModal: false,
      showReasonsModal: false,
      showPayedModal: false,
      selectedRejectReason: null,
      tooltipType: null,
      filterOptions: [
        {
          label: "Comprobante",
          key: "hasVoucher",
          type: Constants.TypeFilters.SINGLE,
          options: [
            LocalConstants.BooleanTypes.YES,
            LocalConstants.BooleanTypes.NO,
          ],
        },
        {
          label: "Estado",
          key: "status",
          type: Constants.TypeFilters.MULTIPLE,
          options: [
            LocalConstants.PaymentStatus.PENDING,
            LocalConstants.PaymentStatus.REVIEW,
            LocalConstants.PaymentStatus.AUTHORIZED,
            LocalConstants.PaymentStatus.APPROVED,
            LocalConstants.PaymentStatus.REJECTED,
            LocalConstants.PaymentStatus.PAID,
            LocalConstants.PaymentStatus.ABONO_CORRECTO,
            LocalConstants.PaymentStatus.ABONO_ENVIADO,
            LocalConstants.PaymentStatus.INCORRECT,
            LocalConstants.PaymentStatus.CANCELED,
            LocalConstants.PaymentStatus.CUENTA_INEXISTENTE,
            LocalConstants.PaymentStatus.DOCUMENTO_ERRADO,
            LocalConstants.PaymentStatus.DOC_NO_CORRESPONDE,
          ],
        },
        {
          label: "Proyecto",
          key: "projectTypeIds",
          type: Constants.TypeFilters.MULTIPLE,
          options: [],
        },
        {
          label: "Zonal",
          key: "zonalIds",
          type: Constants.TypeFilters.MULTIPLE,
          options: [],
        },
        {
          label: "Fecha de registro",
          key: "register",
          type: Constants.TypeFilters.DATE,
          isRange: true
        },
        {
          label: "Fecha de pago",
          key: "payment",
          type: Constants.TypeFilters.DATE,
          isRange: true
        },
        {
          label: "Fecha de aprobación",
          key: "approval",
          type: Constants.TypeFilters.DATE,
          isRange: true
        },
        {
          label: "Empresa",
          key: "businessIds",
          type: Constants.TypeFilters.MULTIPLE,
          options: [],
        },
        {
          label: "Identificador",
          key: "identifier",
          type: Constants.TypeFilters.SINGLE,
          options: [],
        },
        {
          label: "Cuenta verificada",
          key: "isVerified",
          type: Constants.TypeFilters.SINGLE,
          options: [
            LocalConstants.BooleanTypes.YES,
            LocalConstants.BooleanTypes.NO,
          ],
        },
      ],
    };
  },
  mixins: [FilterMixin, PaginationMixin],
  methods: {
    async loadData() {
      try {
        await this.$store.dispatch("app/loading", true);

        const params = {
          page: this.page - 1,
          size: this.pageSize,
        };

        const data = { search: this.search, ...this.filters };
        const response = await PaymentService.list(data, params);

        this.list = response.payload.content.map((item) => {
          return {
            ...item,
            showMobileData: false,
          };
        });

        this.total = response.payload.totalElements;
        this.pages = response.payload.totalPages;
      } catch (e) {
        console.error(e);
        Alert.error(e.errors.message);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    openAuthorizeModal(payment) {
      if (!payment.projectType || !payment.period) {
        this.selectedPaymentId = payment.id;
        this.showAuthorizeModal = true;
      } else {
        this.changeStatus(payment.id, "¿Desea autorizar el estado del pago?", {
          status: LocalConstants.PaymentStatus.AUTHORIZED.key,
          projectType: payment.projectType,
        });
      }
    },
    openPayedModal(paymentId) {
      this.$router.push({
        name: "payments-mark-as-paid",
        params: { id: paymentId },
      });
    },
    openReasonModal(paymentId) {
      this.selectedPaymentId = paymentId;
      this.showReasonsModal = true;
    },
    getStatus(key) {
      return Util.searchValue(key, LocalConstants.PaymentStatus);
    },
    download(asset) {
      if (asset) Util.downloadFile(asset.url, asset.name);
    },
    reject(paymentId, message, data) {
      data.disable = true;
      this.changeStatus(paymentId, message, data);
    },
    cancelPayment(paymentId){
      this.changeStatus(paymentId, "¿Desea anular el pago?", {
        status: LocalConstants.PaymentStatus.CANCELED.key,
      });
    },
    changeStatus(paymentId, message, data) {
      const self = this;
      Alert.dialog(message, null, async function () {
        try {
          await self.$store.dispatch("app/loading", true);
          await PaymentService.changeStatus(paymentId, data);
          self.loadData(this.page, this.pageSize);
          Alert.success("Estado actualizado correctamente");
        } catch (e) {
          console.error(e);
          Alert.error(e.errors.message);
        } finally {
          await self.$store.dispatch("app/loading", false);
        }
      });
    },
    async downloadReport() {
      try {
        await this.$store.dispatch("app/loading", true);
        const data = { search: this.search, ...this.filters };
        const response = await PaymentService.download(data);
        fileDownload(response.data, Util.getFileName(response));
      } catch (error) {
        console.error(error);
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    async downloadMacroBank() {
      try {
        await this.$store.dispatch("app/loading", true);
        const data = { search: this.search, ...this.filters };
        data.voucherTypes = [LocalConstants.VoucherTypes.eInvoice.value,
        LocalConstants.VoucherTypes.eReceipt.value, LocalConstants.VoucherTypes.manualReceipt.value
        ];
        const response = await PaymentService.downloadMacroBank(data);
        fileDownload(response.data, Util.getFileName(response));
      } catch (error) {
        console.error(error);
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    canUploadVoucher(o) {
      if(o.status === LocalConstants.PaymentStatus.PENDING.key){
        return true;
      }

      if(o.status === LocalConstants.PaymentStatus.REJECTED.key && o.voucher &&
        (o.voucher.type === LocalConstants.VoucherTypes.eReceipt.value ||
          o.voucher.type === LocalConstants.VoucherTypes.manualReceipt.value ||
          o.voucher.type === LocalConstants.VoucherTypes.eReceiptForFees.value)){
        return true;
      }
      return false;
    },
    async downloadMacroBankRxh() {
      try {
        await this.$store.dispatch("app/loading", true);
        const data = { search: this.search, ...this.filters };
        data.voucherTypes = [LocalConstants.VoucherTypes.eReceiptForFees.value];
        const response = await PaymentService.downloadMacroBank(data);
        fileDownload(response.data, Util.getFileName(response));
      } catch (error) {
        console.error(error);
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    async downloadAccounting() {
      try {
        await this.$store.dispatch("app/loading", true);
        const data = { search: this.search, ...this.filters };
        const response = await PaymentService.downloadAccounting(data);
        fileDownload(response.data, Util.getFileName(response));
      } catch (error) {
        console.error(error);
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    seeRejectReason(rejectReason) {
      this.selectedRejectReason = rejectReason;
      this.showRejectReasonModal = true;
    },
  },
  async mounted() {
    await this.loadData(this.page, this.pageSize);
  },
  async created() {
    await this.loadData(this.page, this.pageSize);
    const zonalList = (await ZonalService.listAll()).payload;
    const zonalOption = this.filterOptions.find((o) => o.key === "zonalIds");
    zonalList.forEach((b) =>
      zonalOption.options.push({ key: b.id, label: b.description })
    );

    const projectTypes = (await ProjectTypeService.listAll()).payload;
    const projectTypeOption = this.filterOptions.find(
      (o) => o.key === "projectTypeIds"
    );
    projectTypes.forEach((b) => {
      projectTypeOption.options.push({ key: b.id, label: b.description });
      this.projectTypes.push({
        key: b.id,
        label: b.description,
        paymentFrequency: b.paymentFrequency,
      });
    });

    const reasons = (await RejectReasonService.listActive()).payload;
    reasons.forEach((b) =>
      this.rejectReasons.push({ key: b.id, label: b.description })
    );

    const businessList = (await BusinessService.list()).payload;
    const businessOption = this.filterOptions.find(
      (o) => o.key === "businessIds"
    );
    businessList.forEach((b) =>
      businessOption.options.push({ key: b.id, label: b.name })
    );

    const identifiersList = (await PaymentBatchService.listIdentifiers())
      .payload;
    const identifierOption = this.filterOptions.find(
      (o) => o.key === "identifier"
    );
    identifiersList.forEach((b) =>
      identifierOption.options.push({ key: b, label: b })
    );
  },
};
</script>

<style lang="stylus" scoped>
@import '../Styles/payments-list';
</style>
