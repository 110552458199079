export const voucherCodeFormat = function (value) {
  if (!value) return true;
  const parts = value.split("-");
  return parts.length === 2 && parts[0].length > 0 && parts[1].length > 0;
}

export const onlyLettersAndHyphens = function (value) {
  return /^[a-zA-Z0-9+-]+$/.test(value)
}

export default {
  voucherCodeFormat,
  onlyLettersAndHyphens
}
