<template>
  <div class="dashboard-widgets dashboard-container d-xl-flex">
    <div class="dashboard-card left">
      <div>
        <h3 class="dashboard-card-title">Resumen de estado de pagos</h3>

        <section class="dashboard-widgets-items">
          <div>
            <h4 class="dashboard-widgets-title my-0">Pendiente</h4>
            <span
              class="dashboard-widgets-value d-inline-block my-2"
            >{{ summary.pending }}</span>
          </div>

          <div>
            <h4 class="dashboard-widgets-title my-0">Autorizado</h4>
            <span class="dashboard-widgets-value d-inline-block my-2">{{ summary.authorized }}</span>
          </div>

          <div>
            <h4 class="dashboard-widgets-title my-0">Aprobado</h4>
            <span
              class="dashboard-widgets-value d-inline-block my-2"
            >{{ summary.approved }}</span>
          </div>

          <div>
            <h4 class="dashboard-widgets-title my-0">Rechazado</h4>
            <span class="dashboard-widgets-value d-inline-block my-2">{{ summary.rejected }}</span>
          </div>
        </section>
      </div>
    </div>
    <div class="dashboard-card d-flex align-items-center right mt-4 mt-xl-0">
      <section class="mt-4">
        <div>
          <h4 class="dashboard-widgets-title my-0">Pendiente de pago</h4>
          <span class="dashboard-widgets-value d-inline-block my-2">{{ summary.pendingPayment }}</span>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { Alert } from 'wize-admin'

import { DashboardService } from '@/core/services'

export default {
  async mounted() {
    await this.loadData();
  },
  data() {
    return {
      summary: [],
    };
  },
  methods: {
    async loadData() {
      try {
        if(!this.$can('manage', 'portada_cantidad_ids_pago')) return
        await this.$store.dispatch("app/loading", true);
        const response = await DashboardService.list();
        this.summary = response.payload;
      } catch (error) {
        Alert.error(error);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    }
  },
  computed: {
  },
};
</script>

<style lang="stylus">
@import "../Styles/dashboard-widgets.styl"
</style>
