var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.paymentData)?_c('div',{staticClass:"page-container"},[_c('div',{staticClass:"page-title-container"},[_c('h1',{staticClass:"page-title"},[_vm._v("Marcar como pagado N° "+_vm._s(_vm.paymentData.code))])]),_c('div',[_c('b-card',{staticClass:"custom-card",staticStyle:{"max-width":"640px"},attrs:{"tag":"article","no-body":""}},[_c('b-card-header',{staticClass:"custom-card-header d-flex align-items-center",attrs:{"header-tag":"section"}},[_c('h3',[_vm._v("Datos del pago")])]),_c('b-card-body',{staticClass:"custom-card-body p-0"},[_c('form',{staticClass:"custom-form",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('div',{staticClass:"form-section"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label",attrs:{"for":"paymentMethod"}},[_vm._v("Método de pago *")]),_c('FormSelect',{attrs:{"id":"paymentMethod","items":_vm.paymentMethods,"defaultOption":"Elegir método de pago","showError":_vm.$v.form.paymentMethod.$error &&
                        !_vm.$v.form.paymentMethod.required},model:{value:(_vm.form.paymentMethod),callback:function ($$v) {_vm.$set(_vm.form, "paymentMethod", $$v)},expression:"form.paymentMethod"}}),(
                        _vm.$v.form.paymentMethod.$error &&
                        !_vm.$v.form.paymentMethod.required
                      )?_c('FormError',{attrs:{"message":"Método de pago es requerido"}}):_vm._e()],1)]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label",attrs:{"for":"paymentDate"}},[_vm._v("Fecha de abono *")]),_c('FormDatepicker',{staticClass:"custom-form-control p-0",staticStyle:{"border":"0"},attrs:{"label-id":"paymentDate","show-calendar":_vm.showPaymentDate,"max":new Date(),"hide-header":""},on:{"show":function($event){_vm.showPaymentDate = true},"hidden":function($event){_vm.showPaymentDate = false}},model:{value:(_vm.form.paymentDate),callback:function ($$v) {_vm.$set(_vm.form, "paymentDate", $$v)},expression:"form.paymentDate"}}),(
                        _vm.$v.form.paymentDate.$error &&
                        !_vm.$v.form.paymentDate.required
                      )?_c('FormError',{attrs:{"message":"Fecha de abono es requerido"}}):_vm._e()],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label",attrs:{"for":"operationNumber"}},[_vm._v("Número de operación *")]),_c('FormInput',{attrs:{"id":"operationNumber","type":"text","placeholder":"Ingresar número de operación","show-error":_vm.$v.form.operationNumber.$error &&
                        !_vm.$v.form.operationNumber.required},model:{value:(_vm.form.operationNumber),callback:function ($$v) {_vm.$set(_vm.form, "operationNumber", $$v)},expression:"form.operationNumber"}}),(
                        _vm.$v.form.operationNumber.$error &&
                        !_vm.$v.form.operationNumber.required
                      )?_c('FormError',{attrs:{"message":"Número de operación es requerido"}}):_vm._e()],1)]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label",attrs:{"for":"sourceAccount"}},[_vm._v("Cuenta de origen *")]),_c('FormSelect',{attrs:{"id":"sourceAccount","items":_vm.sourceAccounts,"defaultOption":"Elegir cuenta de origen","showError":_vm.$v.form.sourceAccount.$error &&
                        !_vm.$v.form.sourceAccount.required},model:{value:(_vm.form.sourceAccount),callback:function ($$v) {_vm.$set(_vm.form, "sourceAccount", $$v)},expression:"form.sourceAccount"}}),(
                        _vm.$v.form.sourceAccount.$error &&
                        !_vm.$v.form.sourceAccount.required
                      )?_c('FormError',{attrs:{"message":"Cuenta de origen es requerida"}}):_vm._e()],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label",attrs:{"for":"depositBank"}},[_vm._v("Banco de abono *")]),_c('FormSelect',{attrs:{"id":"depositBank","items":_vm.bankList,"defaultOption":"Elegir banco de abono","showError":_vm.$v.form.depositBank.$error &&
                        !_vm.$v.form.depositBank.required},model:{value:(_vm.form.depositBank),callback:function ($$v) {_vm.$set(_vm.form, "depositBank", $$v)},expression:"form.depositBank"}}),(
                        _vm.$v.form.sourceAccount.$error &&
                        !_vm.$v.form.sourceAccount.required
                      )?_c('FormError',{attrs:{"message":"Banco de abono es requerido"}}):_vm._e()],1)]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label",attrs:{"for":"depositAccount"}},[_vm._v("Cuenta de abono *")]),_c('FormInput',{attrs:{"id":"depositAccount","type":"text","placeholder":"Ingresar cuenta de abono","show-error":_vm.$v.form.depositAccount.$error &&
                        !_vm.$v.form.depositAccount.required},model:{value:(_vm.form.depositAccount),callback:function ($$v) {_vm.$set(_vm.form, "depositAccount", $$v)},expression:"form.depositAccount"}}),(
                        _vm.$v.form.depositAccount.$error &&
                        !_vm.$v.form.depositAccount.required
                      )?_c('FormError',{attrs:{"message":"Cuenta de abono es requerida"}}):_vm._e()],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label",attrs:{"for":"payedAmount"}},[_vm._v("Monto de abono *")]),_c('FormInput',{attrs:{"id":"payedAmount","type":"number","min":"0","placeholder":"Ingresar monto de abono","show-error":_vm.$v.form.payedAmount.$error &&
                        !_vm.$v.form.payedAmount.required},model:{value:(_vm.form.payedAmount),callback:function ($$v) {_vm.$set(_vm.form, "payedAmount", $$v)},expression:"form.payedAmount"}}),(
                        _vm.$v.form.payedAmount.$error &&
                        !_vm.$v.form.payedAmount.required
                      )?_c('FormError',{attrs:{"message":"Monto de abono es requerido"}}):_vm._e()],1)]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label",attrs:{"for":"payrollNumber"}},[_vm._v("N° de planilla")]),_c('FormInput',{attrs:{"id":"payrollNumber","type":"text","placeholder":"Ingresar nro. de planilla"},model:{value:(_vm.form.payrollNumber),callback:function ($$v) {_vm.$set(_vm.form, "payrollNumber", $$v)},expression:"form.payrollNumber"}})],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label",attrs:{"for":"payrollDesc"}},[_vm._v("Descripción de planilla")]),_c('FormInput',{attrs:{"id":"payrollDesc","type":"text","placeholder":"Ingresar descripción de planilla"},model:{value:(_vm.form.payrollDesc),callback:function ($$v) {_vm.$set(_vm.form, "payrollDesc", $$v)},expression:"form.payrollDesc"}})],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"records"}},[_vm._v("Cargar constancias")]),_c('FileInput',{staticClass:"w-100 mt-2",attrs:{"multiple":true,"id-file":"records","acceptFile":['.pdf']},on:{"onChange":_vm.onRecordsChange}}),(
                        _vm.$v.form.payedAmount.$error &&
                        !_vm.$v.form.payedAmount.required
                      )?_c('FormError',{attrs:{"message":"Constancias son requeridas"}}):_vm._e()],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"form-group-buttons text-right"},[_c('router-link',{staticClass:"button button-light",attrs:{"to":{ name: 'payments-list' }}},[_vm._v(" Volver ")]),_c('button',{staticClass:"button button-primary",attrs:{"type":"submit"}},[_vm._v(" Guardar ")])],1)])])])])])],1)],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }