<template>
  <div class="dashboard-page">
    <section class="dashboard-banner">
      <h1 class="dashboard-title">Control de pagos</h1>

      <DashboardSummary v-if="$can('manage', 'portada_cantidad_ids_pago')"/>

      <section class="dashboard-container" v-if="$can('manage', 'portada_tabla_dinamica')">
        <div class="mt-4">
          <div class="table-container">
            <div class="table-header">
              <div
                class="d-flex flex-column flex-md-row justify-content-between align-items-start position-relative"
              >
                <div class="w-full w-md-auto md-flex-1">
                  <div
                    class="d-flex justify-content-between align-items-center justify-content-lg-start"
                  >
                    <FormSelect
                      id="range"
                      class="range-select"
                      v-model="filterSelected"
                      :items="selectItems"
                      defaultOption="Elegir periodo"
                      @change="loadData"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div>
              <!-- Desktop -->
              <div>
                <table class="custom-table w-100 d-none d-xl-table">
                  <thead>
                    <tr>
                      <th></th>
                      <th v-for="item in tableHeaderItems" :key="item.key">
                        <span class="table-header-item">{{ item }}</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(value, key) in processedData" :key="key">
                      <td>{{ key }}</td>
                      <td class="text-center" v-for="(subValue, subKey) in value" :key="subKey">
                        {{ subValue }}
                      </td>
                    </tr>
                    <tr v-if="totalByHeader.length">
                      <td>
                        <span class="font-weight-bold">Total</span>
                      </td>
                      <td class="text-center" v-for="total in totalByHeader" :key="total"><span class="font-weight-bold">{{ total }}</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  </div>
</template>

<script>
import { FormSelect, Alert } from "wize-admin";

import { DashboardService } from '@/core/services'

import DashboardSummary from "../Components/DashboardSummary.vue";

export default {
  name: "DashboardPage",
  data() {
    return {
      filterSelected: 'this_month',
      selectItems: [
        { key: "today", label: "Hoy" },
        { key: "yesterday", label: "Ayer" },
        { key: "last_7_days", label: "Últimos 7 días" },
        { key: "this_month", label: "Este mes" },
        { key: "last_month", label: "El mes pasado" },
      ],
      tableHeaderItems: [
        "PENDIENTE",
        "EN REVISION",
        "AUTORIZADO",
        "APROBADO",
        "RECHAZADO",
        "PAGADO",
        "ABONO CORRECTO",
        "ABONO ENVIADO",
        "CUENTA CON ERROR",
        "ANULADO",
        "TOTAL"
      ],
      totalByHeader: [],
      responseData: [],
      processedData: {}
    };
  },
  async mounted() {
    this.filterSelected = this.$route.query.filter || this.selectItems[3].key
    await this.loadData()
  },
  components: {
    FormSelect,
    DashboardSummary,
  },
  methods: {
    async loadData() {
      try {
        if(!this.filterSelected || !this.$can('manage', 'portada_tabla_dinamica')) return

        this.processedData = {}
        this.responseData = []
        this.totalByHeader = []

        this.$router.push({name: this.$route.name, query: {filter: this.filterSelected}})

        this.$store.dispatch("app/loading", true);
        const response = await DashboardService.getTableDetails(this.filterSelected);

        this.responseData = response.payload;

        if(Object.keys(this.responseData).length) {
          this.fillMissingHeaders()
        }
      } catch (e) {
        console.error(e);
        Alert.error(e.errors.message);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    fillMissingHeaders() {
      // Inicializamos totalByHeader con la cantidad de elementos del header
      this.totalByHeader = new Array(this.tableHeaderItems.length).fill(0);

      // Procesamos cada objeto en responseData
      this.processedData = Object.fromEntries(
        Object.entries(this.responseData).map(([key, value]) => {
          // Creamos un objeto donde se agregan los elementos de las cabeceras
          const updatedValue = this.tableHeaderItems.reduce((acc, item, index) => {
            const currentValue = value[item] || 0; // Si no existe, asignamos 0
            acc[item] = currentValue;

            // Sumamos al total de la cabecera correspondiente
            this.totalByHeader[index] += currentValue;

            return acc;
          }, {});

          return [key, updatedValue];
        })
      );
    }
  },
};
</script>

<style lang="stylus">
@import "../Styles/Dashboard.styl"
</style>
