import PaymentsView from "../PaymentsView.vue";
import PaymentList from "../Pages/PaymentList.vue";
import PaymentBatchList from "../Pages/PaymentBatchList.vue";
import PaymentBatchUpload from "../Pages/PaymentBatchUpload.vue";
import PaymentMadeList from "../Pages/PaymentMadeList.vue";
import PaymentMadeUpload from "../Pages/PaymentMadeUpload.vue";
import UploadVoucherPage from "../Pages/UploadVoucherPage.vue";
import PaymentMadeBatchDetails from "../Pages/PaymentMadeBatchDetails.vue";
import PaymentMarkAsPaid from "../Pages/PaymentMarkAsPaid.vue";

const routes = [
  {
    path: '/pagos',
    component: PaymentsView,
    name: 'payments',
    children: [
      {
        path: 'lista',
        name: 'payments-list',
        roles: ['pagos_lista_lista'],
        component: PaymentList
      },
      {
        path: 'cargas',
        name: 'payments-batch-list',
        roles: ['pagos_generar_lista'],
        component: PaymentBatchList
      },
      {
        path: '/:id/marcar-como-pagado',
        name: 'payments-mark-as-paid',
        roles: ['pagos_lista_pagar'],
        component: PaymentMarkAsPaid
      },
      {
        path: 'cargas/carga',
        name: 'payments-batch-upload',
        roles: ['pagos_generar_carga'],
        component: PaymentBatchUpload
      },
      {
        path: 'abonos',
        name: 'payments-made-batch-list',
        roles: ['pagos_abonos_lista'],
        component: PaymentMadeList
      },
      {
        path: 'abonos/:id',
        name: 'payments-made-batch-details',
        roles: ['pagos_abonos_carga'],
        component: PaymentMadeBatchDetails,
      },
      {
        path: 'abonos/carga',
        name: 'payments-made-upload',
        roles: ['pagos_abonos_carga'],
        component: PaymentMadeUpload
      },
      {
        path: ':id/carga',
        name: 'payments-upload-voucher',
        roles: ['pagos_lista_carga_comprobante'],
        component: UploadVoucherPage,
        props: (route) => {
          const id = route.params.id;
          return {id}
        },
      },
    ]
  },
]

export default routes
