import {http} from '@/http'

export default {
  list() {
    return http.get('/dashboard/summary')
  },
  getTableDetails(filter) {
    return http.get(`/dashboard/summary-detail?filter=${filter}`)
  }
}
